// import { useRef, /* useCallback */ } from 'react';
import { useCallback, useRef } from 'react';
import Head from 'next/head';
import { StructuredData } from '~/components/common';
import {
  Footer,
  Header,
  MainSection,
  ScheduleSection,
  SpeakersSection,
  SponsorsSection,
} from '~/components/pages/home';

import eventJsonLD from '~/data/json-ld/event.json';

const META = {
  description:
    'La conferencia sobre desarrollo Web de alcance latinoamericano, organizada por CoDeAr. ' +
    '18 de Noviembre de 2022, de Córdoba para el mundo.',
  ogTitle: 'WebConf LATAM',
  ogSiteName: 'WebConf LATAM',
  ogDescription:
    'La conferencia sobre desarrollo Web de alcance latinoamericano, organizada por CoDeAr. ' +
    '18 de Noviembre de 2022, de Córdoba para el mundo.',
  ogUrl: 'https://webconf.tech',
  ogLocale: 'es_AR',
  ogImage: 'https://webconf.tech/android-chrome-512x512.png',
  twitterTitle: 'WebConf LATAM',
  twitterDescription:
    'La conferencia sobre desarrollo Web de alcance latinoamericano, organizada por CoDeAr. ' +
    '18 de Noviembre de 2022, de Córdoba para el mundo.',
  twitterSite: '@WebConfLATAM',
  twitterCreator: '@WebConfLATAM',
};

const Home = () => {
  const mainSectionRef = useRef(null);
  const goToMainsection = useCallback(
    () => mainSectionRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' }),
    [],
  );

  return (
    <>
      <Head>
        <link rel="stylesheet" href="https://use.typekit.net/kgo5ban.css" />
        <meta name="description" content={META.description} />
        <meta property="og:title" content={META.ogTitle} />
        <meta property="og:site_name" content={META.ogSiteName} />
        <meta property="og:description" content={META.ogDescription} />
        <meta property="og:url" content={META.ogUrl} />
        <meta property="og:locale" content={META.ogLocale} />
        <meta property="og:image" content={META.ogImage} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={META.twitterTitle} />
        <meta name="twitter:description" content={META.twitterDescription} />
        <meta name="twitter:site" content={META.twitterSite} />
        <meta name="twitter:creator" content={META.twitterCreator} />
        <StructuredData jsonLD={eventJsonLD} />
      </Head>
      <Header onLearnMore={goToMainsection} />
      <MainSection ref={mainSectionRef} />
      <SpeakersSection />
      <ScheduleSection />
      <SponsorsSection />
      <Footer />
    </>
  );
};

export default Home;
